<template>
  <div class="admin-wrap">
    <AdminMenu active-item="vacation" />
    <div class="admin-main">
      <AdminHeader title="休假管理" />
      <div class="vacation-management">
        <div class="vacation-control">
          <div class="vacation-control__days">
            <button class="vacation-control__btn-prev" @click="onPrevDay"></button>
            <span class="vacation-control__current">{{date}}</span>
            <button class="vacation-control__btn-next" @click="onNextDay"></button>
          </div>
          <input type="date" v-model="date">
          <div class="site-search site-search--admin">
            <input type="text" v-model="keyword">
            <button>搜尋</button>
          </div>
        </div>
        <div class="vacation-management__employee-wrap">
          <div class="vacation-management__group" v-for="job in jobs" :key="job">
            <h4>{{job.name}}</h4>
            <div class="vacation-management__wrap">
              <div class="vacation-management__employee-item" v-for="staff in filterJobStaffs(job.id)" :key="staff">
                <input type="checkbox" :value="staff.id" :checked="vacationStaffIds.includes(staff.id)" @click="toggleOnVacation(staff.id)">
                <label for="">{{staff.fullName}}</label>
              </div>
            </div>
            <div v-if="job.name === '美容師'" class="vacation-management__option">
              <div class="vacation-management__column">
                <label for="option-value">支援其它療程的美容師人數</label>
                <input type="text" id="option-value" v-model="xNumber">
              </div>
              <div class="vacation-management__column">
                <label for="option-people">同時段可預約的美療人數</label>
                <input type="text" id="option-people" :value="beauticianBookable" readonly>
              </div>
            </div>
          </div>
        </div>
        <button class="admin-btn-lg" @click="onSave">儲存</button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminMenu from '../../components/AdminMenu'
import AdminHeader from '../../components/AdminHeader'
import { formatDate } from '../../utils/utils'
import { apiGetVacation, apiSetVacation, apiGetStaffs, apiGetJobs } from '@/api/v1/admin'

export default {
  name: 'Vacation',
  components: { AdminMenu, AdminHeader },
  computed: {
    beauticianBookable () {
      const availableBeauticianCount = this.staffs.filter(s => s.jobId === 3 && !this.vacationStaffIds.includes(s.id)).length
      return availableBeauticianCount > this.xNumber ? availableBeauticianCount - this.xNumber : 0
    }
  },
  created () {
    this.fetchData()
    this.date = formatDate(new Date())
  },
  data () {
    return {
      keyword: '',
      date: '',
      jobs: [],
      staffs: [],
      jobStaffs: {},
      vacationStaffIds: [],
      xNumber: 2
    }
  },
  watch: {
    date (newDate) {
      apiGetVacation(newDate).then(data => {
        this.xNumber = data.xnumber
        this.vacationStaffIds = data.staffIds
      })
    }
  },
  methods: {
    fetchData () {
      apiGetJobs().then(data => {
        if (!data.error) {
          this.jobs = data
          apiGetStaffs().then(data => {
            if (!data.error) {
              this.staffs = data.content
              this.jobs.forEach(job => {
                this.jobStaffs[job.id] = this.staffs.filter(s => s.jobId === job.id).map(st => { return { id: st.id, fullName: st.fullName } })
              })
            }
          })
        }
      })
    },
    onNextDay () {
      const d = new Date(this.date)
      d.setDate(d.getDate() + 1)
      this.date = formatDate(d)
    },
    onPrevDay () {
      const d = new Date(this.date)
      d.setDate(d.getDate() - 1)
      this.date = formatDate(d)
    },
    filterJobStaffs (jobId) {
      if (this.jobStaffs[jobId]) {
        return this.jobStaffs[jobId].filter(st => st.fullName.includes(this.keyword))
      }
      return []
    },
    toggleOnVacation (staffId) {
      const i = this.vacationStaffIds.indexOf(staffId)
      if (i === -1) {
        this.vacationStaffIds.push(staffId)
      } else {
        this.vacationStaffIds.splice(i, 1)
      }
    },
    onSave () {
      const payload = {
        staffIds: this.vacationStaffIds,
        xnumber: this.xNumber
      }
      apiSetVacation(this.date, payload).then(data => {
        if (data.error) {
          this.$swal('儲存失敗', data.message, 'warning')
        } else {
          this.$swal('儲存成功', '', 'success')
        }
      }).catch(err => {
        this.$swal('儲存失敗', err.data.error.message, 'warning')
      }).finally(() => {
      })
    }
  }
}
</script>

<style>

</style>
